import { Injectable,Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlGroup, TriggerEventConfigDetails, TriggerAndJobConfigDetails } from '../models/config.model';
import { APIService } from './api.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class TriggerAndJobService {

  constructor(@Inject(ConfigService) private configService: ConfigService, private apiService: APIService) { }
  get urls(): UrlGroup {
    return this.configService.getApiUrls('adminconfiguration');
  }

  public GetClientTriggerEventAndJobSetupDetails(clientConfigurationId : number): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/clientconfiguration/` + clientConfigurationId  + `/getClientTriggerEventAndJobSetupDetails`;
    return this.apiService.get<any>(url);
  }

  public createClientTriggerAndJob(data: any): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/clientconfiguration/CreateClientTriggerEventAndJobSetup`;
    return this.apiService.post<number>(url, data);
  }

  public updateClientTriggerAndJob(data: TriggerAndJobConfigDetails): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/clientconfiguration/UpdateClientTriggerEventAndJobSetup`;
    return this.apiService.put<number>(url, data);
  }
}
