<app-contentheader headerText="SMS History"></app-contentheader>

<div class="card-box">
  <form class="k-form">
    <div class="row">
      <kendo-grid
      [reorderable]="true"
      [kendoGridBinding]="twiliosmsApiData"
      filterable="menu"
      [pageable]="{
        pageSizes: pageSizes
      }"

      [pageSize]="10"
      [resizable]="true"
        [sortable]="true"
        [groupable]="true"
         scrollable="scrollable"
         style="color: #fff"
         cssClass="grid-height" >
        <ng-template kendoGridToolbarTemplate>
          <!-- <input [style.width.px]="500" placeholder="Search in all columns..." kendoTextBox (input)="onFilter($event)" /> -->

          <kendo-grid-spacer></kendo-grid-spacer>

          <button kendoGridExcelCommand type="button" icon="file-excel" title="Download Excel"></button>
          <button kendoGridPDFCommand type="button" icon="file-pdf" title="Download Pdf"></button>
          <kendo-grid-column-chooser></kendo-grid-column-chooser>
        </ng-template>
        <kendo-grid-column field="id" title="Id" [width]="65" [headerClass]="'headerCustomClass'"
          filter="numeric"></kendo-grid-column>
        <kendo-grid-column field="phoneNumber" title="Phone Number" [width]="120"
          [headerClass]="'headerCustomClass'"></kendo-grid-column>

        <kendo-grid-column field="twilioSID" title="Twilio SID" [width]="100"
          [headerClass]="'headerCustomClass'"></kendo-grid-column>
          <kendo-grid-column field="twilioMessageStatus" title="Twilio Status" [width]="100"
          [headerClass]="'headerCustomClass'"></kendo-grid-column>
          <kendo-grid-column field="claimId" title="Claim Id" [width]="100"
          [headerClass]="'headerCustomClass'"></kendo-grid-column>
          <kendo-grid-column field="docketIdForOptInRequested" title="Docket Id For OptIn" [width]="130"
          [headerClass]="'headerCustomClass'"></kendo-grid-column>


        <kendo-grid-column field="isDocketAddedInCMS" title="Is Docket Added" filter="boolean"  [width]="115" [headerClass]="'headerCustomClass'">
        </kendo-grid-column>
        <kendo-grid-column field="isOptedIn" title="Is Opted In" filter="boolean" [width]="115"></kendo-grid-column>
        <kendo-grid-column field="docketType" title="Docket Type" [width]="120" >
        </kendo-grid-column>

        <kendo-grid-column field="optedOutReadSMSMessage" title="Opted Out Message" [filterable]="false" [headerClass]="'headerCustomClass'"
          [width]="170" [hidden]="true"><ng-template kendoGridCellTemplate let-dataItem>
            <button kendoButton style="color: #fff; background-color: #193971 !important"
            (click)="open(dataItem.optedOutReadSMSMessage)">
              Opted Out Message
            </button>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="errorMessage" title="Message" [filterable]="false" [headerClass]="'headerCustomClass'"
          [width]="130"><ng-template kendoGridCellTemplate let-dataItem>
            <button kendoButton style="color: #fff; background-color: #193971 !important"
              (click)="openmessage(dataItem.errorMessage)">
              Error Message
            </button>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="twilioMessageBody" title="Twilio Message Body" [filterable]="false" [headerClass]="'headerCustomClass'"
        [width]="170" [hidden]="true"><ng-template kendoGridCellTemplate let-dataItem>
          <button kendoButton style="color: #fff; background-color: #193971 !important"
          (click)="openBody(dataItem.twilioMessageBody)">
          Twilio Message Body
          </button>
        </ng-template>
      </kendo-grid-column>
        <kendo-grid-column field="createdDate" title="Created Date" [width]="170" [hidden]="true">
        </kendo-grid-column>

        <kendo-grid-column field="createdBy" title="Created By" [hidden]="true" [headerClass]="'headerCustomClass'"
          [width]="150"></kendo-grid-column>
        <kendo-grid-column field="modifiedBy" title="Modified By" [hidden]="true" [headerClass]="'headerCustomClass'"
          [width]="150"></kendo-grid-column>
        <kendo-grid-column field="modifiedDate" title="Modified Date" [hidden]="true"
          [headerClass]="'headerCustomClass'" [width]="150"></kendo-grid-column>

        <kendo-grid-pdf [fileName]="'SMS History.pdf'" [allPages]="true">
          <!-- <kendo-grid-pdf-margin
            top="2cm"
            left="1cm"
            right="1cm"
            bottom="2cm"
          ></kendo-grid-pdf-margin>
          <ng-template
            kendoGridPDFTemplate
            let-pageNum="pageNum"
            let-totalPages="totalPages"
          >
            <div class="page-template">
              <div class="header">
                <div style="float: right">
                  Page {{ pageNum }} of {{ totalPages }}
                </div>
              </div>
              <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
            </div>
          </ng-template> -->
        </kendo-grid-pdf>
        <kendo-grid-excel fileName="SMS History.xlsx" [fetchData]="allData"></kendo-grid-excel>
      </kendo-grid>
      <ng-container *ngIf="ismessageTextboxVisible">
        <kendo-dialog [title]="Title" *ngIf="openedmessage" (close)="close('cancel')">
          <div class="textareawrapmsg">
            <kendo-textarea #Textbox resizable="none" [(value)]="message" [readonly]="true"
              style="width: 800px; height: 100px;"></kendo-textarea>
          </div>
        </kendo-dialog>
      </ng-container>

       <ng-container *ngIf="isTextareaVisible">
        <kendo-dialog [title]="Title" *ngIf="opened" (close)="close('cancel')">
          <div class="textareawrap2">
            <kendo-textarea #Textarea resizable="none" [(value)]="optedOutReadSMSMessage" [readonly]="true"
              style="width: 800px; height: 100px; overflow: hidden;"></kendo-textarea>
          </div>
        </kendo-dialog>
      </ng-container>
      <ng-container *ngIf="isTextareaVisibleBody">
        <kendo-dialog [title]="Title" *ngIf="openedBody" (close)="close('cancel')">
          <div class="textareawrapmsg">
            <kendo-textarea #Textbox resizable="none" [(value)]="twilioMessageBody" [readonly]="true"
              style="width: 800px; height: 100px;"></kendo-textarea>
          </div>
        </kendo-dialog>
      </ng-container>
    </div>
  </form>
</div>
