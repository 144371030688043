import * as exp from "constants";

export interface Config {
  apiUrls: ApiUrls;
  auth: Auth;
}
export interface Auth {
  ClientID: string;
  APIConsumerKey: string;
  ClientSecret:string;
  expires_in:string;
  authorizedOn:string;
  tokenType:string;
}
export interface UrlGroup {
  PrefixURLCommand: string;
  PrefixURLRead: string;
  oData: string;
  //prefixurl: string;
  //ClientID: string;
  //APIConsumerKey: string;
}

export interface ApiUrls {
  [key: string]: any;
  adminconfiguration: UrlGroup;
  anonymous: string;
}

export class ClientConfig {
  id: number;
  clientConfigurationId: number;
  clientId: number;
  clientName: string;
  createdBy: string;
  modifiedBy: string;
  filePath: string;
  firstClaimIdToProcess: number;
  isActive: boolean;
  fetchPreviousClaims: boolean;
  isNotificationNeededForFailedClaims: boolean;
  lastProcessedClaimId: number;
  bcpClientId: number;
  lastProcessedTriggerObjectId: number;
  organizationId: any = [];
  sourceSystemId: number;
}

export class JobSetup {
  id: number;
  clientJobSetupId: number;
  clientConfigurationId: number;
  jobName: string;
  jobFrequencyId: number;
  clientId: number;
  jobDuration: number;
  dayOfWeekMonth: number;
  jobStartTime: string;
  retryJobCount: number;
  lastExecuted: string;
  isActive: boolean;
  createdBy: string;
  // createdDate: string;
  modifiedBy: string;
  // modifiedDate: string;
}

export class TriggerEventConfigDetails {
  id: number;
  clientTriggerEventConfigurationId: number;
  clientConfigurationId: number;
  clientId: number;
  triggerType: string;
  triggerTypeId: number;
  timeCode: string;
  timeCodeId: string;
  expenseCode: string;
  expenseCodeId: number;
  trackedTableName: string;
  trackedTablesId: string;
  isActive: boolean;
  createdBy: string;
  // createdDate: string;
  modifiedBy: string;
  // modifiedDate: string;
}

export class TriggerAndJobConfigDetails{
  clientTriggerEventConfigurationId: number;
  clientJobSetupId: number;
  clientConfigurationId: number;
  clientId: number;
  triggerType: string;
  triggerTypeId: number;
  timeCode: string;
  timeCodeId: string;
  expenseCode: string;
  expenseCodeId: number;
  trackedTableName: string;
  trackedTablesId: string;
  isActive: boolean;
  createdBy: string;
  // createdDate: string;
  modifiedBy: string;
  // modifiedDate: string;
  jobName: string;
  jobFrequencyId: number;
  jobDuration: number;
  dayOfWeekMonth: number;
  jobStartTime: string;
  retryJobCount: number;
  lastExecuted: string;
}

export class EmailConfig {
  id: number;
  clientId: number;
  clientName: string;
  triggerTypeId: number;
  triggerType: string;
  toEmails: string;
  isActive: boolean;
  ccEmail: string;
  bccEmail: string;
  subject: string;
  body: string;
  isJsonNeedsToBeAttached: boolean;
  createdBy: string;
  // createdDate: string;
  modifiedBy: string;
  // modifiedDate: string;
}

export class OnDemandProcessingDetails {
  id: number;
  clientId: number;
  clientName: string;
  jobName: string;
  triggerJobId: number;
  startClaimId: number;
  endClaimId: number;
  triggerTypeId: number;
  timeCodeIds: number;
  timeCodes: string;
  isNotificationRequiredForUnprocessedClaim: boolean;
  isRangeOfClaims: boolean;
  triggeredBy: string;
  trigerredOn: string;
  isStoppedProcessing: boolean;
  createdBy: string;
  // createdDate: string;
  modifiedBy: string;
  // modifiedDate: string;
}

export class Sourcesystem {
  id: number;
  name: string;
  isActive: boolean;
  createdBy: string;
  modifiedBy: string;
}

export class Organizations {
  id: number;
  orgName: string;
  orgCode: string;
  createdBy: string;
  isActive: boolean;
  modifiedBy: string;
}

export class Trackedtables {
  id: number;
  tableName: string;
  isActive: boolean;
  createdBy: string;
  isRequiredToClaimFetch: boolean;
  trackedColumnName: string;
}

export class Timecode {
  id: number;
  code: string;
  isActive: boolean;
  createdBy: string;
  modifiedBy: string;
}

export class Expensecode {
  id: number;
  code: string;
  isActive: boolean;
  createdBy: string;
  modifiedBy: string;
}

export class Client {
  id: number;
  name: string;
  isActive: boolean;
  createdBy: string;
  modifiedBy: string;
}

export class History {
  total: number;
  id: number;
  clientJobSetupId: number;
  clientJobSetupName: string;
  clientId: number;
  clientName: string;
  //jsonData: string;
  message: string;
  targetRefrenceId: string;
  // createdDate: string;
  status: string;
  processedClaimId: number;
  processedTriggerObjectId: number;
  isEmailSent: boolean;
  jobRetriedCount: number;
  // createdBy: string;
  // modifiedBy: string;
  // modifiedDate: string;
}

export class user {
  id: number;
  address: string;
  userId: string;
  company: string;
  contactNo: number;
  isActive: boolean;
  createdBy: string;
  modifiedBy: string;
  role:string;
  // createdDate: string;
  // modifiedDate: string;


}

export class consumerAPI {
  id: string;
  clientId: string;
  apiConsumerKey: string;
  name: string;
}
export class TwilioSMSClaimDetails {
  id: number;
  claimId: number;
  phoneNumber: string;
  clientId: number;
  twilioSID: string;
  errorMessage: string;
  twilioMessageStatus: string;
  docketIdForOptInRequested: number;
  docketType: string;
  isDocketAddedInCMS: boolean;
  optedOutReadSMSMessage: string;
  // createdDate: string;
  isOptedIn: boolean;
  createdBy: string;
  modifiedBy: string;
  // modifiedDate: string;
}
