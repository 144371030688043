<app-contentheader headerText="Client Setup"></app-contentheader>
<div class="card-box" *ngIf="!showTabs">
  <div [style.height.px]="50">
    <button _ngcontent-whw-c5 kendoButton size="medium" rounded="medium"
      style="color: #fff; background-color: #193971 !important" (click)="addClient()">
      Add Client Setup
    </button>
  </div>
  <kendo-grid [kendoGridBinding]="clientGridView" [reorderable]="true" filterable="menu" cssClass="grid-height"
    [pageSize]="10" [pageable]="true" [sortable]="true" [groupable]="true" [reorderable]="true" [resizable]="true"
    [pageable]="{
      pageSizes: pageSizes
    }" (cellClick)="cellClicked($event)">
    <ng-template kendoGridToolbarTemplate>
      <input [style.width.px]="500" placeholder="Search in all columns..." kendoTextBox (input)="onFilter($event)" />
      <kendo-grid-spacer></kendo-grid-spacer>
      <button kendoGridExcelCommand type="button" icon="file-excel" title="Download Excel"></button>
      <button kendoGridPDFCommand icon="file-pdf" title="Download Pdf"></button>
      <kendo-grid-column-chooser></kendo-grid-column-chooser>
    </ng-template>
    <kendo-grid-column field="clientConfigurationId" title="Id" [width]="65" class="fabric-grid-column fabric-column-name click-link ng-star-inserted">
    </kendo-grid-column>
    <kendo-grid-column field="clientName" title="Client Name" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="organizationDtls" title="Organization Name" [width]="180">
    </kendo-grid-column>
    
    <kendo-grid-column field="lastExecuted" title="Last Executed" [width]="190" [hidden]="true">
    </kendo-grid-column>
    <kendo-grid-column field="createdBy" title="Created By" [width]="140">
    </kendo-grid-column>
    <kendo-grid-column field="modifiedBy" title="Modified By" [width]="140" [hidden]="true">
    </kendo-grid-column>
    <kendo-grid-column filter="boolean" field="isActive" title="Is Active" [width]="140">
    </kendo-grid-column>
    <!-- <kendo-grid-column field="Action" [filterable]="false" [width]="80">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button kendoButton style="color: #fff; background-color: #193971 !important" (click)="editClient(dataItem)">
          Edit
        </button>
      </ng-template>
    </kendo-grid-column> -->
    <kendo-grid-pdf fileName="Client Configuration.pdf" [allPages]="true" [repeatHeaders]="true"></kendo-grid-pdf>
    <kendo-grid-excel fileName="Client Configuration.xlsx" [fetchData]="allData"></kendo-grid-excel>
  </kendo-grid>
  <br />
</div>
<div *ngIf="showTabs">
  <app-tabstrip [clientConfigurationId]="clientConfigurationId" [sourceSystemMasterData]="sourceSystemMasterData"
    [clientMasterData]="clientMasterData" [orgMasterData]="orgMasterData"></app-tabstrip>
</div>
