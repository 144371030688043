<!-- <div class="card-box" >
  Tab disabled, Please configure Client first
</div> -->
<div class="card-box">
  <form [formGroup]="triggerJobForm" >
    <div formArrayName="triggerJobArr" style="
    gap: 8px;
    display: flex;
    flex-direction: column;
">
      
     <div *ngFor="let triggerJob of triggerJob.controls; let i = index">
      <div [formGroupName]="i">

        <kendo-expansionpanel title="Job Setup  {{i+1}}">
     <div class="row">
      <div class="col-md-3 col-sm-12 mb-4" (change)="onChangeResponsibility(i)">
        <kendo-formfield>
          <kendo-floatinglabel text="Select Trigger Type">
            <kendo-dropdownlist placeholder="" [filterable]="true" textField="type" valueField="id" [value]="selTriggerType"
              (filterChange)="triggerTypeFilter($event)" 
              [formControlName]="'triggerType'" [data]="triggerTypeData" #triggerType required>
            </kendo-dropdownlist>
          </kendo-floatinglabel>
          <kendo-formerror>Trigger Type required</kendo-formerror>
        </kendo-formfield>
      </div>
       <div class="col-md-3 col-sm-12 mb-4" *ngIf="selectedSourceSystem === 'CMS'">
        <kendo-formfield>
          <kendo-label text="Select Table(s) To Be Tracked"></kendo-label>
          <kendo-multiselect placeholder="" [filterable]="true" [data]="filteredAllTrackedData"
            [value]="selectedTrackedTables" [checkboxes]="true" [autoClose]="false" [textField]="'tableName'"
            [valueField]="'id'" (filterChange)="tableToTrackFilter($event)" 
            [formControlName]="'tableToTrack'"></kendo-multiselect>
          <kendo-formerror *ngIf="getFormControl['tableToTrack']?.errors?.['required']">Tables to be tracked is
            required</kendo-formerror>
        </kendo-formfield>
      </div>


     
       <div class="col-md-3 col-sm-12 mb-4" *ngIf="selectedSourceSystem === 'CMS' && isResponsibilityPercentageSelected(i)">
        <kendo-label text="Select Time Code To Be Tracked"></kendo-label>
        <kendo-multiselect placeholder="" [checkboxes]="true"  [autoClose]="false"  [value]="selectedTimeCodes"  [filterable]="true" textField="code" valueField="id"
        (filterChange)="timeCodeFilter($event)" [formControlName]="'timeCodeToBeTracked'" [data]="filteredTimeCodeMasterData" > 
      </kendo-multiselect>
      

    </div>
  </div> 
  

 
   <div class="row" >
    <div class="col-md-3 col-sm-12 mb-4" (change)="onFrequencyResponsibility(i)">
      <kendo-formfield>
        <kendo-floatinglabel class="k-empty k-floating-label-container" text="Select Frequency">
          <kendo-dropdownlist
            [data]="filteredFrequencyData"
            textField="frequency"
            placeholder=""
            valueField="id"
            [formControlName]="'jobFrequencyId'"
            [filterable]="true"
            (filterChange)="frquencyFilter($event)"
            [value]="selectedFrequency"
            #frequency
            required
          >
          </kendo-dropdownlist>
        </kendo-floatinglabel>
        <kendo-formerror>Frequency required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div
      class="col-md-3 col-sm-12 mb-4"     style="left: 65px;"
      *ngIf="isMonthlySelected(i)"
    >
      <kendo-formfield>
        <kendo-floatinglabel text="Select Date of Month">
          <kendo-dropdownlist
            [style.width.px]="170"
            placeholder=""
            [formControlName]="'dayOfWeekMonth'"
            textField="text"
            valueField="value"
            [data]="monthDays"
            (filterChange)="monthFilter($event)"
            required
          ></kendo-dropdownlist>
        </kendo-floatinglabel>
      </kendo-formfield>
    </div>
    <div
      class="col-md-3 col-sm-12 mb-4" style="
      left: 65px;
  "
      *ngIf="isWeeklySelected(i)"
    >
      <kendo-formfield>
        <kendo-floatinglabel text="Select Day of Week">
          <kendo-dropdownlist
            [formControlName]="'dayOfWeekMonth'"
            textField="text"
            valueField="value"
            [data]="weekDays"
            (filterChange)="daysFilter($event)"
            [style.width.px]="170"
            required
          >
          </kendo-dropdownlist>
        </kendo-floatinglabel>
      </kendo-formfield>
    </div>
    <div
      class="col-md-3 col-sm-12 mb-4"
      *ngIf="isHourlySelected(i)" style="
      left: 65px;
  "
    >
      <kendo-formfield>
        <kendo-floatinglabel text="Hourly Duration">
          <kendo-numerictextbox
            [style.width.px]="170"
            [formControlName]="'jobDuration'"
            [autoCorrect]="autoCorrect"
            [min]="minHour"
            [max]="maxHour"
            format="n"
            required
          ></kendo-numerictextbox>
        </kendo-floatinglabel>
      </kendo-formfield>
    </div>
    <div
      class="col-md-3 col-sm-12 mb-4"
      *ngIf="isMinuteSelected(i)" style="
      left: 65px;
  "
    >
      <kendo-formfield>
        <kendo-label
          text="Duration in Minute"
          style="margin-bottom: 8px !important"
        ></kendo-label>
        <kendo-numerictextbox
          [style.width.px]="170"
          [autoCorrect]="autoCorrect"
          [max]="max"
          [min]="min"
          format="n"
          [formControlName]="'jobDuration'"
          required
        ></kendo-numerictextbox>
      </kendo-formfield>
    </div>

    <div class="row" style="
    display: flex;
    justify-content: space-between;
    left: 18px;position: relative;
">
    <div class="col-md-3 col-sm-12 mb-4">
      <kendo-formfield>
        <kendo-floatinglabel text="Job Name">
          <kendo-textbox [readonly]="isJobSetupDisabled" [formControlName]="'jobName'" required style="
          width: max-content;
      ">
          </kendo-textbox>
        </kendo-floatinglabel>
        <kendo-formerror>Job Name is required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col-md-3 col-sm-12 mb-4">
      <kendo-formfield>
        <kendo-label 
          text="Job Start Time (in UTC)"
          style="display: flex;width: max-content;
          margin-bottom: 4px !important"
        ></kendo-label>
        <kendo-datetimepicker style="
        width: max-content;
        top: 9px;
    "
          format="MM/dd/yyyy HH:mm"
          [value]="selDate"
          [rounded]="rounded"
          [fillMode]="fillMode"
          [size]="size"
          calendarType="infinite"
          [formControlName]="'jobStartTime'"
          required
        ></kendo-datetimepicker>
        <kendo-formerror>Job Start Time is required</kendo-formerror>
      </kendo-formfield>
    </div>
    
  </div>
  </div> 
  <div class="row">
    <div class="col-md-3 col-sm-12 mb-4">
      <kendo-floatinglabel text="Retry Job Count in Case of Failure">
        <kendo-numerictextbox
          [formControlName]="'retryJobCount'"
          format="n"
          [min]="0"
          [max]="3"
        >
        </kendo-numerictextbox>
      </kendo-floatinglabel>
    </div>
  <div class="col-md-3 col-sm-12 mb-4">
    <kendo-label class="k-checkbox-label" style="
    padding: 22px 12px  50px 0px;
">
      &nbsp;
      <input [formControlName]="'isActive'" type="checkbox" #isClientActive kendoCheckBox />
      Is Active
    </kendo-label>
  </div>
  </div>
</kendo-expansionpanel>
  </div> 
  </div>
  </div>


  </form>
 


    <div class="row" style="
    padding-bottom: 29px;
    top: 30px;
    position: relative;
    padding-left: 15px;
">
      <div class="col-md-3 col-sm-12 ">
        <button
          kendoButton
          _ngcontent-whw-c5
          style="color: #fff; background-color: #193971 !important"
          [routerLink]="['../ClientSetup']"
          (click)="exit()"
        >
          Back
        </button>
        <kendo-button
          _ngcontent-whw-c5
          style="
            color: #fff;
            background-color: #193971 !important;
            margin-left: 30px;
          "
          (click)="saveTriggerAndJob()"  
          >Save</kendo-button
        >

        
            <button *ngIf="addmoreshow"
              kendoButton
              _ngcontent-whw-c5
              style="color: #fff; background-color: #193971 !important;  margin-left: 30px;" 
              [routerLink]="['../ClientSetup']"
              (click)="addTriggerJob()"
            >
              Add more
            </button>
          


      </div>
    </div>

    <!-- {{this.triggerJobForm.value | json}} -->
  

</div>

<!-- </kendo-expansionpanel> -->


  <div kendoDialogContainer></div>



