<div class="row" class="nav-item">
  <!-- <div class="row" class="add-btn">
    <button
      _ngcontent-whw-c5
      kendoButton
      [routerLink]="['/ClientSetup']"
      size="medium"
      rounded="medium"
      style="color: #fff; background-color: #193971 !important"
      (click)="exit()"
    >
      Back
    </button>
  </div> -->
  <!-- <label id="disabledTabs" value="true">true</label> -->
  <kendo-tabstrip (tabSelect)="onTabSelect($event)">
    <kendo-tabstrip-tab title="Client Configuration" [selected]="true">
      <ng-template kendoTabContent>
        <app-client-config [clientConfigurationId]="clientConfigurationId" [clientMasterData]="clientMasterData" [orgMasterData]="orgMasterData" [sourceSystemMasterData]="sourceSystemMasterData" ></app-client-config>
      </ng-template>
    </kendo-tabstrip-tab>

    <!-- <kendo-tabstrip-tab title="Trigger Event">
      <ng-template kendoTabContent>
        <app-trigger-event [clientConfigurationId]="clientConfigurationId" [allTrackedMasterData]="allTrackedMasterData" [expenseCodeMasterData]="expenseCodeMasterData" [timeCodeMasterData]="timeCodeMasterData"></app-trigger-event>
      </ng-template>
    </kendo-tabstrip-tab> -->

    <kendo-tabstrip-tab title="Job Setup">
      <ng-template kendoTabContent>
        <app-job-setup [clientConfigurationId]="clientConfigurationId" [frequecyMasterData]="frequecyMasterData"  [allTrackedMasterData]="allTrackedMasterData" [expenseCodeMasterData]="expenseCodeMasterData" [timeCodeMasterData]="timeCodeMasterData" ></app-job-setup>
      </ng-template>
    </kendo-tabstrip-tab>

  </kendo-tabstrip>
</div>
