import { Component, Inject, Input } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ClientConfig } from 'src/app/models/config.model';
import { ClientService } from 'src/app/services/client.service';
import { MasterdataService } from 'src/app/services/masterdata.service';
import { Sourcesystem } from 'src/app/interfaces/sourcesystem';
import { Organizations } from 'src/app/interfaces/organizations';
import { Client } from 'src/app/interfaces/client';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { JobSetupService } from 'src/app/services/job-setup.service';
import { JobSetup } from 'src/app/models/config.model';
import { TriggerEventService } from 'src/app/services/trigger-event.service';
import { TriggerEventConfigDetails } from 'src/app/models/config.model';

import { Location } from '@angular/common';
import { elementAt, takeWhile } from 'rxjs/operators';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/utils';

import { Observable } from 'rxjs';

// import * as objectHash from 'object-hash/index';

@Component({
  selector: 'app-client-config',
  templateUrl: './client-config.component.html',
  styleUrls: ['./client-config.component.scss'],
})
export class ClientConfigComponent {
  public clientApiData: ClientConfig;
  public jobSetupApiData: JobSetup;
  public triggerEventApiData: TriggerEventConfigDetails;

  @Input()
  clientConfigurationId: number = 0;
  public selectedClientValue: Number = 0;
  public selectedOrgValue: Number = 0;
  public selectedSourceFilterValue: Number = 0;

public client1: Client[]=[];
  public clientconfig: ClientConfig[] = [];

  public clientForm: FormGroup;
  LoggedInName: string;
  existingData: any;
  dataExist: boolean = false;
  get getFormControl() { return this.clientForm.controls; }

  public isEditMode: boolean = false;
  @Input() sourceSystemMasterData: Sourcesystem[] = [];
  filteredSourceSystem: Sourcesystem[] = [];


  @Input() clientMasterData: Client[] = [];
  filteredClientData: Client[] = [];

  @Input() orgMasterData: Organizations[]  = [];
  filteredOrgData: Organizations[] = [];

  public clientValidator: any = {};
  // isSaveButtonDisabled: boolean = true;
  // isFormDirty : boolean = false;
  allowCustom = true;
  public selectedClient: string;
  public clifilter: string;
  public sourcesysfilter: string;
  public organizationfilter: string;
  public orgIdStr: string = "";
  public orgUpdate: string = "";
  public selectedorganization: any[] = [];

  constructor(@Inject(ClientService) private clientService: ClientService, @Inject(JobSetupService) private jobSetupService: JobSetupService, @Inject(TriggerEventService) private triggerEventService: TriggerEventService, @Inject(MasterdataService) private masterdataService: MasterdataService, private fb: FormBuilder, private dialogService: DialogService, private _location: Location) {


    this.clientForm = this.fb.group({
      clientConfigurationId: new FormControl(),
      clientId: new FormControl(),
      clientName: new FormControl(this.clientValidator.clientName, [Validators.required]),
      createdBy: new FormControl(),
      firstClaimIdToProcess: new FormControl(null, [Validators.pattern("^[0-9]+$")]),
      isActive: new FormControl(true),
      isNotificationNeededForFailedClaims: new FormControl(true),
      bcpClientId: new FormControl(this.clientValidator.bcpClientId, [Validators.required]),
      lastProcessedClaimId: new FormControl(),
      fetchPreviousClaims: new FormControl(false),
      lastProcessedTriggerObjectId: new FormControl(),
      //organizationId: new FormControl([this.clientValidator.organizationId], [Validators.required]),
      organizationId: new FormControl([], [Validators.required]),
      sourceSystemId: new FormControl(this.clientValidator.sourceSystemId, [Validators.required])
    })


  }

  ngOnInit(): void {
    // this.isSaveButtonDisabled = true;

    this.filteredSourceSystem = this.sourceSystemMasterData;
    this.filteredClientData = this.clientMasterData;
    this.filteredOrgData = this.orgMasterData;
    this.existingData = JSON.parse(localStorage.getItem("stringifiedObj"));
    console.log(this.existingData.length)
    if (this.clientConfigurationId == 0) {
      this.clientConfigurationId = parseInt(localStorage.getItem("clientConfigurationId"));
    }
    if (this.clientConfigurationId > 0) {
      this.isEditMode = true;
      this.getClientData(this.clientConfigurationId);
    }
    // this.clientForm.valueChanges.subscribe(() => {
    //   this.isFormDirty = this.clientForm.dirty;
    //   this.isSaveButtonDisabled = !this.isFormDirty;
    // });


}
public addNew(): any {
  this.clientFilter(this.clifilter);
  console.log(this.clifilter);
   let data: Client = {
  id : 0,
  name : this.clifilter,
  isActive : true,
  createdBy : this.LoggedInName = sessionStorage.getItem('username')
  }
  this.postClientData1(data)
}
public addNewOrg(): void {
  this.orgFilter(this.organizationfilter);
  let data: Organizations = {
  id : 0,
  orgName : this.organizationfilter,
  orgCode : this.organizationfilter,
  isActive : true,
  createdBy : this.LoggedInName  = sessionStorage.getItem('username')
}
  this.postOrganizationData(data)

}
public addNewSourceSys(): void {
  this.sourceFilter(this.sourcesysfilter);
  let data: Sourcesystem = {
  id : 0,
  name : this.sourcesysfilter,
  isActive : true,
  createdBy : this.LoggedInName = sessionStorage.getItem('username')
  }
  this.postSourcesystemData(data);
  
}


// public lastItemValue: number = this.clientMasterData[this.clientMasterData.length - 1].id;


  public clientFilter(value: string) {
    // this.clientMasterData[0] = {id:0, name:"Add New Client",isActive:true,createdBy:''}
    this.clifilter = value;

    this.filteredClientData = this.clientMasterData;
    this.filteredClientData = this.clientMasterData.filter(
      (s) => s.name.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }

  public orgFilter(value: string) {
    this.organizationfilter = value;
    this.filteredOrgData = this.orgMasterData;
    this.filteredOrgData = this.orgMasterData.filter(
      (s) => s.orgName.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }

  public sourceFilter(value: string) {
    this.sourcesysfilter = value;
    this.filteredSourceSystem = this.sourceSystemMasterData;
    this.filteredSourceSystem = this.sourceSystemMasterData.filter(
      (s) => s.name.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }
  public onClientChange(value) {
    this.selectedClient = value;
  }


  public getFormData(): any {
    let data: ClientConfig = new ClientConfig();
    this.LoggedInName = sessionStorage.getItem('username');

    const formValue = this.clientForm.getRawValue();
    if (this.isEditMode) {
      data.id = Number(localStorage.getItem('clientConfigurationId'));
      data.clientId = Number(localStorage.getItem('clientId'));
      data.clientName = formValue.clientName;
    // data.organizationId = this.orgUpdate;


    }
    else {
      data.clientId = formValue.clientName.id;
      data.clientName = formValue.clientName.name;
      localStorage.setItem('clientName', data.clientName);

    }
    data.organizationId = this.orgIdStr;

    //data.organizationId = +formValue.organizationId?.id ? +formValue.organizationId.id : 0;
    data.sourceSystemId = +formValue.sourceSystemId?.id ? +formValue.sourceSystemId.id : 0;
    data.isActive = formValue.isActive;
    data.fetchPreviousClaims = formValue.fetchPreviousClaims;
    data.firstClaimIdToProcess = formValue.firstClaimIdToProcess;
    data.lastProcessedClaimId = +formValue.lastProcessedClaimId;
    data.lastProcessedTriggerObjectId = +formValue.lastProcessedTriggerObjectId;
    data.isNotificationNeededForFailedClaims = formValue.isNotificationNeededForFailedClaims;
    data.bcpClientId = formValue.bcpClientId
    if (+this.isEditMode > 0) {
      data.modifiedBy = this.LoggedInName;
      // data.modifiedBy = localStorage.getItem(this.storageKeys.createdBy);
    }
    else {
      data.createdBy = this.LoggedInName;
      //data.createdBy = localStorage.getItem(this.storageKeys.createdBy);
    }

    let sourceSystemName = this.sourceSystemMasterData.find(item => item.id === data.sourceSystemId).name;
    //console.log("source", this.source);
    //console.log(data.sourceSystemId);
    //console.log("sourceSystem", sourceSystemName);
    localStorage.setItem('clientId', data.clientId?.toString());
    localStorage.setItem('isActive', data.isActive.toString());
    localStorage.setItem('clientName', data.clientName);
    localStorage.setItem('sourceSystem', sourceSystemName);
    localStorage.setItem('orgCode', data.organizationId.toString());
    // localStorage.setItem('firstClaimIdToProcess',data.firstClaimIdToProcess.toString());
    return data;
  }

  public saveClient() {
    let data = this.getFormData();
    console.log("data",data);
    if (this.clientConfigurationId > 0) {
      this.putClientData(data);
      // this.updateRelatedData(data);
    } 
    else if(data){
      for(let i = 0; i<=this.existingData.length;i++){
      this.existingData[i].clientId  == data.clientId;
      
      if(this.existingData[i].clientId  == data.clientId){
        this.dataExist = true;
      console.log("this is it");
      this.showConfirmation("Error", "Client configuration already created for this client");
      }
      // else{
      //   if(this.dataExist == false){
      //     this.postClientData(data);
      //   this.dataExist = true;

      break;

      //   }
      // }
      }
      if(this.dataExist == false){
      this.postClientData(data);

      }
    }
    else {
      // this.postClientData(data);

      // this.clientForm.pristine = false;
      // document.getElementById('disabledTabs').innerText = this.clientConfigurationId?this.clientConfigurationId.toString():"";
    }
    // this.isSaveButtonDisabled = true;
  }
  // public saveDisable(): any{
  //   if(!this.clientForm.dirty){
  //      this.isSaveButtonDisabled = true;
  //     }
  //     else{
  //       this.isSaveButtonDisabled = false;
  //     }
  // }


  public updateRelatedData(data: any) {
    this.jobSetupService.GetClientJobSetUpByClientConfigurationId(this.clientConfigurationId).subscribe(jobSetupData => {
      jobSetupData.isActive = data.isActive;
      jobSetupData.id = jobSetupData.clientJobSetupId;

      if (jobSetupData.id>0) {
        this.jobSetupService.putClientJobSetup(jobSetupData).subscribe((resp) => {
          if (resp.isSuccess) {
            console.log("Client job setup updated successfully");
          }
          else {
            console.log("Error: " + resp.message);
          }
        })
      }
    })
    this.triggerEventService.getClientTriggerEventConfigurationDetails(this.clientConfigurationId).subscribe(triggerEventData => {
      triggerEventData.isActive = data.isActive;
      triggerEventData.id = triggerEventData.clientTriggerEventConfigurationId;

      if (triggerEventData.id > 0) {
        this.triggerEventService.updateClientTriggerEventConfiguration(triggerEventData).subscribe((resp) => {
          if (resp.isSuccess) {
            console.log("Trigger event updated successfully");
          }
          else {
            console.log("Error: " + resp.message);
          }
        })
      }
    })
  }

  public putClientData(data: any) {
    this.clientService.putClient(data).subscribe((resp) => {
      if (resp.isSuccess) {
        this.showConfirmation("Success", "Client configuration updated successfully");
        console.log("Client configuration updated successfully");
        this.getClientData(this.clientConfigurationId);
      } else {
        this.showConfirmation("Error", "Error: " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log("update error1");
    });
  }

  public postClientData(data: any) {

    this.clientService.postClient(data).subscribe((resp) => {
      if (resp.isSuccess) {
        this.clientConfigurationId = resp.id;
        this.showConfirmation("Success", "Client configuration added successfully");
        console.log("Client configuration added successfully");
        this.getClientData(this.clientConfigurationId);
      } else {
        this.showConfirmation("Error", "Error: " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log(error.message);
    });
  }
  public postClientData1(data: any) {
    console.log(data);
    this.masterdataService.postAllClient(data).
      subscribe((resp) => {
        // this.name = resp.name;
        if (resp.isSuccess) {
          this.showConfirmation("Success", "Client added successfully");
          // this.getClientData();
        this.masterdataService.getAllClient();
        } else {
          this.showConfirmation("Error", "Error: " + resp.message);

        }
      }, (error: any) => {
        console.log("error1");
      });
      this.masterdataService.getAllClient().subscribe(data => {
        this.clientMasterData = data;
      });
  }
  public postOrganizationData(data: any) {
    console.log(data);
    this.masterdataService.postAllOrganization(data).
    subscribe((resp) => {
      // this.orgName = resp.orgName;
      // this.orgCode = resp.orgCode;
      //this.createdBy = resp.createdBy;
      if (resp.isSuccess) {
        this.showConfirmation("Success","Organization added successfully");
        console.log("Organization added successfully");
        // this.getOrganizationData();
      } else {
        this.showConfirmation("Error","Error: " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log("error1");
    });
  }
  public postSourcesystemData(data: any) {
    console.log(data);
    this.masterdataService.postAllSourceSystem(data).
      subscribe((resp) => {
        //this.name = resp.name;
        // this.createdBy = resp.createdBy;
        if (resp.isSuccess) {
          this.showConfirmation("Success", "Source system added successfully");
          console.log("Source system added successfully");
          //this.postAllSourceSystem();
          this.masterdataService.getAllSourceSystem();
        } else {
          this.showConfirmation("Error", "Error: " + resp.message);
          console.log("Error: " + resp.message);
        }
      }, (error: any) => {
        console.log(error.message);
      });
      this.masterdataService.getAllSourceSystem().subscribe(data => {
        this.sourceSystemMasterData = data;
      });
  }

  public getClientData(clientConfigurationId: number) {
    if (clientConfigurationId > 0) {
      this.clientService.getClientConfiguration(clientConfigurationId).subscribe(data => {
        this.clientApiData = data;
        if (this.clientApiData) {
        // let organizationData = [];

        // organizationData = (this.clientApiData.organizationDtls != null) ? this.orgMasterData?.filter(item => this.clientApiData.organizationId?.split(',').includes(item.id.toString())) : '';
        // if (this.clientApiData.organizationId) {
        //   this.selectedorganization = this.orgMasterData?.filter(item => this.clientApiData.organizationId?.split(',').includes(item.id.toString()));
        // }
          let selectedOrgApiData= JSON.parse(this.clientApiData['organizationDtls']);
          let selectedOrgId = [];
          selectedOrgApiData.forEach(element => {
            selectedOrgId.push(element.OrganizationId)
          })
          // let organizationIdArr = selectedOrgId;
          let selectedOrgData = [];
          selectedOrgId.forEach(id => {
           let index = this.orgMasterData.findIndex((item) => item.id  == id);
            if(index >=0){
              selectedOrgData.push(this.orgMasterData[index])
            } 
          });

         console.log("selectedOrg",selectedOrgData)
         for(let i = 0; i<selectedOrgData.length; i++){
         this.orgIdStr += selectedOrgData[i].id + ',';
        }
        //  this.orgIdStr = selectedOrgData.join
        //  this.orgUpdate = selectedOrgData;
         
        //  for(let i = 0; i<selectedOrgData.length; i++){
        //   this.orgIdStr += selectedOrgData[i].organizationId + ',';
          
        //  }
         this.orgIdStr = this.orgIdStr.replace(/,\s*$/, "");
        console.log(this.orgIdStr);
         
        // console.log(organizationIdstr)

          this.clientForm.patchValue({
            // organizationId: this.orgMasterData.find(item => item.id === organizationIdstr),
            organizationId: selectedOrgData,
            sourceSystemId: this.sourceSystemMasterData.find(item => item.id === this.clientApiData.sourceSystemId),
            firstClaimIdToProcess: this.clientApiData.firstClaimIdToProcess,
            bcpClientId: this.clientApiData.bcpClientId,
            isActive: this.clientApiData.isActive,
            fetchPreviousClaims: this.clientApiData.fetchPreviousClaims,
            isNotificationNeededForFailedClaims: this.clientApiData.isNotificationNeededForFailedClaims,
            clientName: this.clientApiData.clientName
          });
          let sourceSystemId = this.sourceSystemMasterData.find(item => item.id === this.clientApiData.sourceSystemId).name;
          localStorage.setItem('clientId', this.clientApiData.clientId?.toString());
          localStorage.setItem('clientConfigurationId', this.clientConfigurationId.toString());
          localStorage.setItem('isActive', this.clientApiData.isActive.toString());
          localStorage.setItem('clientName', this.clientApiData?.clientName);
          localStorage.setItem('sourceSystem', sourceSystemId);
          localStorage.setItem('orgCode', this.clientApiData.organizationId?.toString());
          //document.getElementById('disabledTabs').innerText = this.clientConfigurationId ? this.clientConfigurationId.toString():"";
        }
      });
    }
  }

  public exit() {
    if (this.clientForm.dirty && this.isEditMode) {
      this.showConfirmationOnBack("Please Confirm","You have unsaved data on the page.Do you want to continue?");
    } else {
      window.location.reload();
    }
  }

  public result;
  public showConfirmation(dialogTitle, dialogContent): void {

    const dialog: DialogRef = this.dialogService.open({
      title: dialogTitle,
      content: dialogContent,
      actions: [{ text: "Close", themeColor: "primary", cssClass: "modal-close-button" }],
      width: 400,
      height: 150,
      minWidth: 250,

    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else {
        console.log("action", result);
      //  this.filteredSourceSystem = this.sourceSystemMasterData;
       this.masterdataService.getAllSourceSystem().subscribe(data => {
        this.sourceSystemMasterData = data;
      });
      this.masterdataService.getAllClient().subscribe(data => {
        this.clientMasterData = data;
      });
    this.filteredClientData = this.clientMasterData;
       this.filteredSourceSystem = this.sourceSystemMasterData;

      }
      this.result = JSON.stringify(result);
    });
    // if(this.clientForm.dirty)
    // this.isSaveButtonDisabled = true;

  }


  public showConfirmationOnBack(dialogTitle,dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: dialogTitle,
      content: dialogContent,
      actions: [{ text: "Yes" }, { text: "No", themeColor: "primary" }],
      width: 350,
      height: 170,
      minWidth: 250

    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else if (result.text == "Yes") {
          //this._location.back();
          window.location.reload();
        }
      this.result = JSON.stringify(result);
    });
  }

  disabled() {
    if (this.isEditMode) {
      if (!this.clientForm.valid || !this.clientForm.dirty) {
        return true;

      }
    } else{
      if (!this.clientForm.valid) {
        return true;
      }
    }
    return false;
  }

  onValueChange(event) {
    console.log(event);
    let orgIdArr = [];
    event.forEach(element => {
      orgIdArr.push(element.id);
    });
    //let orgIdStr = 
   this.orgIdStr = orgIdArr.join(',');
    //const selectedValues = event.value;
    //console.log(selectedValues);
  }

   

}
