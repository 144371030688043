import { Component, OnInit, Input, INJECTOR } from '@angular/core';
import { SelectEvent } from "@progress/kendo-angular-layout";
import { Client } from 'src/app/interfaces/client';
import { Organizations } from 'src/app/interfaces/organizations';
import { Sourcesystem } from 'src/app/interfaces/sourcesystem';
import { MasterdataService } from 'src/app/services/masterdata.service';

@Component({
  selector: 'app-tabstrip',
  templateUrl: './tabstrip.component.html',
  styleUrls: ['./tabstrip.component.scss']
})
export class TabstripComponent implements OnInit {
  @Input()
  clientId: number;

  @Input()
  clientConfigurationId: number;

  // isTabsDisabled:boolean=false;
  allTrackedMasterData:any = [];
  timeCodeData:any = [];
  triggerType: any = [];
  timeCodeMasterData:any = [];
  expenseCodeMasterData: any;
  frequecyMasterData:any = [];
  @Input() sourceSystemMasterData:Sourcesystem[] = [];
  @Input() clientMasterData: Client[] = [];
  @Input() orgMasterData:Organizations[] = [];
  constructor(private masterdataService: MasterdataService) { }

  ngOnInit(): void {
    this.masterdataService.getAllTrackedTable().subscribe(data => {
      this.allTrackedMasterData = data.filter(s => s.isActive == true);
      // document.getElementById('disabledTabs').innerText = this.clientId?this.clientId.toString():"";
      // this.isTabsDisabled = this.clientId ? true:false;
      // console.log("this.isTabsDisabled:"+this.isTabsDisabled);
    });

    this.masterdataService.getAllTimecode().subscribe(data => {
      this.timeCodeMasterData = data.filter(s => s.isActive == true);
    });
    this.masterdataService.getAllTriggerType().subscribe(data => {
      this.triggerType = data.filter(s => s.isActive == true);
    })

    this.masterdataService.getAllExpensecode().subscribe(data => {
      this.expenseCodeMasterData = data.filter(s => s.isActive == true);
    });

    this.masterdataService.getAllJobfrequency().subscribe(data => {
      this.frequecyMasterData = data.filter(s => s.isActive == true);
    });



  }



  public onTabSelect(e: SelectEvent): void {
    console.log(e);
}

}
